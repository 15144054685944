import "./App.css";

export default function App() {
    return (
        <div className="App">
            <div className="App-left">
                <div className="App-left-top">
                    <p className="top-link-home">
                        <a className="home-link" href="/">
                            ersa.dev://
                        </a>
                    </p>
                </div>
                <div className="App-left-bottom">
                    <p className="bottom-text-home">
                        it is not death that a man should fear; <br /> he should
                        fear never beginning to live
                    </p>
                </div>
            </div>
            <div className="App-right"></div>
        </div>
    );
}
