import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../home/App";
import ErrorPage from "../errorhandlers/ErrorPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
    },
]);
